import { authorized } from "../authorized";

export interface APIError {
  field?: string;
  message?: string;
  help?: string;
}

export interface APIResponse {
  errors?: APIError[];
  status: number;
}

export interface FetchAuthenticateTokenResponse extends APIResponse {
  password_reset_required?: boolean;
  setup_2fa_required?: boolean;
  token?: string;
}

export interface FetchTwoFAResponse extends APIResponse {
  is_verified?: boolean;
}

export interface FetchSessionTokenResponse extends APIResponse {
  password_reset_required?: boolean;
  setup_2fa_required?: boolean;
  token?: string;
}

export interface FetchUnifiedSearchResponse extends APIResponse {
  result: { id: number; type: "mp" | "unified" }[];
}

export interface FetchIdentifierResponse extends APIResponse {
  login_type: string;
}

export interface FetchSSOResponse extends APIResponse {
  idp_url: string;
  saml_request: string;
  relay_state: string;
}

export const fetchIdentifier = async (username: string): Promise<Response> => {
  const baseURL = getBaseURL();
  // Call the gandalf identifier endpoint
  return fetch(`${baseURL}/v3/public/identifier`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username }),
  });
};

export const fetchSSO = async (username: string): Promise<Response> => {
  const baseURL = getBaseURL();
  return fetch(`${baseURL}/v3/public/sso/saml/request/${encodeURIComponent(username)}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  });
};

export const fetchAuthenticateToken = async (
  username: string,
  password: string
): Promise<Response> => {
  const baseURL = getBaseURL();
  return fetch(`${baseURL}/v3/public/tokens`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  });
};

export const deleteAuthenticateToken = async (token: string): Promise<Response> => {
  const baseURL = getBaseURL();
  const fetch = authorized(token);
  return fetch(`${baseURL}/v3/tokens`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token: token }),
  });
};

export const fetchTwoFASetting = async (token: string): Promise<Response> => {
  const baseURL = getBaseURL();
  const fetch = authorized(token);
  return fetch(`${baseURL}/v3/public/access_settings/multifactor`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const fetchSessionToken = async (access_token: string) => {
  const baseURL = getBaseURL();
  return fetchWithTimeout(`${baseURL}/v3/sessions`, {
    timeout: 5000,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });
};

export interface FetchAcceptInviteResponse extends APIResponse {
  token: string;
  setup_2fa_required: boolean;
}

export interface FetchAcceptInviteBody {
  first_name: string;
  last_name: string;
  email: string;
  username: string;
}

export const fetchAcceptInvite = async (
  token: string,
  body: FetchAcceptInviteBody
): Promise<Response> => {
  const baseURL = getBaseURL();
  return fetch(`${baseURL}/v3/public/teammates/${token}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
};

export const fetchUnifiedSearch = async (access_token: string, id_token: string) => {
  const baseURL = getBaseURL();
  return fetch(`${baseURL}/v3/unified_search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify({
      id_token,
    }),
  });
};

export const fetchUnifiedSignup = async (
  access_token: string,
  id_token: string
): Promise<Response> => {
  const baseURL = getBaseURL();
  return fetch(`${baseURL}/v3/unified_signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify({
      id_token,
    }),
  });
};

/**
 * Call unified link endpoint to link two users together.
 * @param token
 * @param id_token
 */
export const fetchUnifiedLink = async (token: string, id_token: string): Promise<Response> => {
  const baseURL = getBaseURL();
  const fetch = authorized(token);
  return fetch(`${baseURL}/v3/unified_link`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id_token,
    }),
  });
};

export type UnifiedProvisionPlanResponse =
  | {
      eligible: true;
      email: string;
      auth0_login_exists: boolean;
      rollout_phase: number;
      base: number;
    }
  | {
      eligible: false;
      reason: string;
    };

/**
 * Call unified provision plan to check account linking eligibility.
 * @param token
 */
export const fetchUnifiedProvisionPlan = async (token: string): Promise<Response> => {
  const baseURL = getBaseURL();
  const fetch = authorized(token);
  return fetch(`${baseURL}/v3/unified_provision/plan`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * Call unified provision to link sendgrid account to twilio account.
 * @param token
 * @param id_token
 */
export const fetchUnifiedProvision = async (token: string, id_token: string): Promise<Response> => {
  const baseURL = getBaseURL();
  const fetch = authorized(token);
  return fetch(`${baseURL}/v3/unified_provision`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id_token,
    }),
  });
};

export interface RequestInitWithTimeout extends RequestInit {
  // Timeout
  timeout?: number;
}

export const fetchWithTimeout = async (
  input: string | URL | Request,
  options: RequestInitWithTimeout = {}
): Promise<Response> => {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  if (input instanceof URL) {
    const response = await fetch(input, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);

    return response;
  } else if (input instanceof Request) {
    const response = await fetch(input, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);

    return response;
  } else {
    const response = await fetch(input as string, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);

    return response;
  }
};

const getBaseURL = (): string => {
  return process.env.REACT_APP_API_BASE_URL || "https://api.sendgrid.com";
};
